<template>
  <v-card class="pa-5">
    <h5 class="body-1 primary--text font-weight-bold">Filter Cars ({{filterCount}} Cars)</h5>
    <v-divider class="my-3"></v-divider>
    <div @click="showPriceFilter = !showPriceFilter" class="d-flex justify-space-between">
      <h5 class="body-1 font-weight-bold paragraph--text mb-2">Prices</h5>
      <v-icon right v-text="showPriceFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
    </div>
    <v-expand-transition>
      <div v-show="showPriceFilter">
        <v-row justify="space-between" v-if="min < max">
          <v-subheader class="subheaderGrey--text caption">${{prices[0]}}</v-subheader>
          <v-subheader class="subheaderGrey--text caption">${{prices[1]}}</v-subheader>
        </v-row>
        <v-range-slider
        v-model="prices"
        :min="min"
        :max="max"
        color="primary"
        hide-details
        class="align-center"
        thumb-label
        @change="filterate()"
        >
        </v-range-slider>
      </div>
    </v-expand-transition>
    <v-divider class="my-3"></v-divider>
    <div @click="showCatFilter = !showCatFilter" class="d-flex justify-space-between">
      <h5 class="body-1 font-weight-bold paragraph--text mb-2">Categories</h5>
      <v-icon right v-text="showCatFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
    </div>
    <v-expand-transition>
      <div v-show="showCatFilter">
        <v-btn text small color="primary" @click="allCars('cat')">All Categories <v-icon right>mdi-chevron-right</v-icon></v-btn>
        <!-- <v-btn active-class="active" text v-for="cat in categories" :key="cat.category" class="my-5" block @click="selectedCat = cat.category; filterate();">
          <v-row justify="space-between">
            <span class="caption primary--text">{{cat.category}}</span>
            <v-spacer></v-spacer>
            <div>
              <span class="caption paragraph--text">{{cat.numberOfCars}} Cars</span>
              <v-icon color="paragraph">mdi-chevron-right</v-icon>
            </div>
          </v-row>
        </v-btn> -->
        <v-checkbox
        v-for="cat in categories"
        :key="cat.category"
        v-model="selectedCat"
        :label="cat.category + cat.numberOfCars + 'Cars'"
        :value="cat.category"
        @change="filterate()"
        ></v-checkbox>
      </div>
    </v-expand-transition>
    <v-divider class="my-3"></v-divider>
    <div @click="showSeatsFilter = !showSeatsFilter" class="d-flex justify-space-between">
      <h5 class="body-1 font-weight-bold paragraph--text mb-2">Seats</h5>
      <v-icon right v-text="showSeatsFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
    </div>
    <v-expand-transition>
      <div v-show="showSeatsFilter">
        <v-btn text small color="primary" @click="allCars('seat')">All Seats <v-icon right>mdi-chevron-right</v-icon></v-btn>
        <!-- <v-btn text v-for="seat in seats" :key="seat.numberOfSeats" class="my-5" block @click="selectedSeat = seat.numberOfSeats; filterate();">
          <v-row justify="space-between">
            <span class="caption primary--text">{{seat.numberOfSeats}} Seats </span>
            <v-spacer></v-spacer>
            <div>
              <span class="caption paragraph--text"> {{seat.numberOfCars}} Cars</span>
              <v-icon color="paragraph">mdi-chevron-right</v-icon>
            </div>
          </v-row>
        </v-btn> -->
        <v-checkbox
        v-for="seat in seats"
        :key="seat.numberOfSeats"
        v-model="selectedSeat"
        :label="seat.numberOfSeats + 'Seats' + seat.numberOfCars + 'Cars'"
        :value="seat.numberOfSeats"
        @change="filterate()"
        ></v-checkbox>
      </div>
    </v-expand-transition>
    <v-divider class="my-3"></v-divider>
    <div @click="showVendorsFilter = !showVendorsFilter" class="d-flex justify-space-between">
      <h5 class="body-1 font-weight-bold paragraph--text mb-2">Vendors</h5>
      <v-icon right v-text="showVendorsFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
    </div>
    <v-expand-transition>
      <div v-show="showVendorsFilter">
        <v-btn text color="primary" @click="allCars('vendor')">All Vendors <v-icon right>mdi-chevron-right</v-icon></v-btn>
        <!-- <v-btn text v-for="vendor in vendors" :key="vendor.vendor" class="my-5" block @click="selectedVendor = vendor.vendor; filterate();">
          <v-row justify="space-between">
            <span class="caption primary--text">{{vendor.vendor}} </span>
            <v-spacer></v-spacer>
            <div>
              <span class="caption paragraph--text">{{vendor.numberOfCars}} Cars</span>
              <v-icon color="paragraph">mdi-chevron-right</v-icon>
            </div>
          </v-row>
        </v-btn> -->
        <v-checkbox
        v-for="vendor in vendors"
        :key="vendor.vendor"
        v-model="selectedVendor"
        :label="vendor.vendor + vendor.numberOfCars + 'Cars'"
        :value="vendor.vendor"
        @change="filterate()"
        ></v-checkbox>
      </div>
    </v-expand-transition>
    <v-divider class="my-3"></v-divider>
    <v-btn text color="primary" @click="getAllCars()">All Cars <v-icon right>mdi-chevron-right</v-icon></v-btn>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      cars: [],
      filterCount: 0,
      prices: [],
      min: 0,
      max: 0,
      categories: [],
      seats: [],
      vendors: [],
      selectedCat: [],
      selectedVendor: [],
      selectedSeat: [],
      showPriceFilter: true,
      showCatFilter: true,
      showSeatsFilter: true,
      showVendorsFilter: true
    }
  },
  methods: {
    filterate () {
      const filtered = []
      // this.cars.forEach(car => {
      //   if (
      //     this.prices[0] <= car.fareInfo[car.fareInfo.length - 1].Amount && car.fareInfo[car.fareInfo.length - 1].Amount <= this.prices[1] &&
      //     car.carInfo.carCategory === (this.selectedCat ? this.selectedCat : car.carInfo.carCategory) &&
      //     car.carInfo.seats === ((this.selectedSeat || this.selectedSeat === 0) ? this.selectedSeat : car.carInfo.seats) &&
      //     car.vendorName === (this.selectedVendor ? this.selectedVendor : car.vendorName)
      //   ) {
      //     filtered.push(car)
      //   }
      // })
      // const criteria = {
      //   prices: this.prices,
      //   cat: this.selectedCat ? this.selectedCat : 'all categories',
      //   seats: this.selectedSeat ? this.selectedSeat : 'any number of',
      //   vendors: this.selectedVendor ? this.selectedVendor : 'any vendor'
      // }
      this.cars.forEach(car => {
        if (this.prices[0] <= car.fareInfo[car.fareInfo.length - 1].Amount && car.fareInfo[car.fareInfo.length - 1].Amount <= this.prices[1]) {
          if (
            (this.selectedCat.includes(car.carInfo.carCategory) || !this.selectedCat.length) &&
            (this.selectedSeat.includes(car.carInfo.seats) || !this.selectedSeat.length) &&
            (this.selectedVendor.includes(car.carInfo.vendorName) || !this.selectedVendor.length)
          ) filtered.push(car)
        }
      })
      this.$emit('changed', filtered, '')
    },
    allCars (type) {
      const filtered = []
      if (type === 'cat') {
        this.selectedCat = []
        this.cars.forEach(car => {
          if (this.prices[0] <= car.fareInfo[car.fareInfo.length - 1].Amount <= this.prices[1] && car.carInfo.carCategory === (this.selectedCat ? this.selectedCat : car.carInfo.carCategory)) {
            filtered.push(car)
          }
        })
        // const criteria = {
        //   prices: this.prices,
        //   cat: 'all categories',
        //   seats: this.selectedSeat,
        //   vendors: this.selectedVendor
        // }
        this.$emit('changed', filtered, '')
      } else if (type === 'seats') {
        this.selectedSeat = []
        this.cars.forEach(car => {
          if (this.prices[0] <= car.fareInfo[car.fareInfo.length - 1].Amount <= this.prices[1] && car.carInfo.seats === (this.selectedSeat ? this.selectedSeat : car.carInfo.seats)) {
            filtered.push(car)
          }
        })
        // const criteria = {
        //   prices: this.prices,
        //   cat: this.selectedCat,
        //   seats: 'any number of',
        //   vendors: this.selectedVendor
        // }
        this.filterCount = filtered.length
        this.$emit('changed', filtered, '')
      } else {
        this.selectedVendor = []
        this.cars.forEach(car => {
          if (this.prices[0] <= car.fareInfo[car.fareInfo.length - 1].Amount <= this.prices[1] && car.vendorName === (this.selectedVendor ? this.selectedVendor : car.vendorName)) {
            filtered.push(car)
          }
        })
        // const criteria = {
        //   prices: this.prices,
        //   cat: this.selectedCat,
        //   seats: this.selectedSeat,
        //   vendors: 'all vendors'
        // }
        this.filterCount = filtered.length
        this.$emit('changed', filtered, '')
      }
    },
    getAllCars () {
      const filtered = []
      this.cars.forEach(car => {
        filtered.push(car)
      })
      this.selectedSeat = []
      this.selectedCat = []
      this.selectedVendor = []
      this.prices = [this.min, this.max]
      const criteria = null
      this.filterCount = filtered.length
      this.$emit('changed', filtered, criteria)
    }
  },
  created () {
    this.min = Math.floor(this.$store.state.cars.filterPrice.minPrice)
    this.max = Math.floor(this.$store.state.cars.filterPrice.maxPrice)
    this.prices = [this.min, this.max]
    this.categories = this.$store.state.cars.categories
    this.seats = this.$store.state.cars.seats
    this.vendors = this.$store.state.cars.vendors
    this.cars = [...this.$store.state.cars.cars]
    this.filterCount = this.cars.length
  }
}
</script>

<style>
.v-slider--horizontal .v-slider__track-container {
height: 4px !important;
}
.vue-range-slider.slider-component .slider .slider-process {
background-color: #FF8F95;
}
.vue-range-slider.slider-component .slider .slider-dot {
box-shadow: 0px 3px 6px #00000029;
}
.v-slider__thumb::after {
  content: "";
  width: 14px !important;
  height: 14px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  box-shadow: 1px 1px 1px 2px #cccccc4a;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
</style>
