<template>
    <div>
        <overlay v-if="$store.state.carsTimeOut" :newSearch="getCars" />
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
          <v-row>
            {{ text }}
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="snackbar = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>
        <div class="car-search-bg">
            <v-container>
                <!-- <v-row justify="center" class="ma-5" align="center">
                  <v-card class="pa-5 pt-10 rounded-xl"> -->
                    <div v-if="$store.state.flightType === 'round'" class="mb-5">
                        <div class="d-flex">
                        <div class="heading-md text-bold white--text">{{$store.state.carInfo.pickupLocation.Name}}</div>
                        <div class="heading-md text-bold white--text">/{{$store.state.carInfo.dropLocation ? $store.state.carInfo.dropLocation.Name : $store.state.carInfo.pickupLocation.Name}}</div>
                        </div>
                    </div>
                    <carSearch @addCars="getCars"></carSearch>
                  <!-- </v-card>
                </v-row> -->
            </v-container>
        </div>
        <v-container fluid>
            <!-- loader -->
            <v-row justify="center" v-if="!cars && loaded" class="mb-10">
                <v-progress-circular
                :size="70"
                :width="7"
                class="mt-15"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </v-row>
            <v-row v-if="cars && !loaded">
                <v-col cols="12" md="3" class="pt-0">
                  <carFilter @changed="filter"/>
                </v-col>
                <v-col cols="12" md="9" class="mt-5">
                  <carCard :cars="cars"></carCard>
                </v-col>
            </v-row>
            <p v-if="!cars && !loaded" class="body-1 font-weight-bold primary--text text-center mt-5">Sorry, No cars match your search .</p>
        </v-container>
    </div>
</template>

<script>
import carCard from '@/components/cars/card'
import carSearch from '@/components/cars/searchForm'
import overlay from '@/components/flights/overlay'
import carFilter from '@/components/cars/filters'
import { carsSearch } from '@/links'

export default {
  title: '-Cars Search Results',
  components: {
    carCard,
    carSearch,
    overlay,
    carFilter
  },
  data () {
    return {
      cars: null,
      criteria: null,
      snackbar: false,
      color: '',
      text: '',
      loaded: true
    }
  },
  computed: {
    carsStore () {
      return this.$store.state.cars
    }
  },
  watch: {
    carsStore (newVal) {
      this.cars = newVal
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    filter (cars, criteria) {
      this.cars.cars = [...cars]
      this.criteria = criteria
    },
    getCars (term) {
      this.$store.dispatch('removeCarsResults')
      this.loaded = true
      this.axios.get(carsSearch(term)).then(response => {
        if (response.data.status === false) {
          this.snackbar = true
          this.color = 'error'
          this.text = response.data.message
          this.loaded = false
        } else {
          this.loaded = false
          this.$store.dispatch('setCars', response.data.data)
          this.$store.dispatch('setCarsTimeOut', false)
          this.$store.dispatch('setCarsSearchTimeOutFunction', setTimeout(() => {
            this.$store.dispatch('setCarsTimeOut', true)
          }, 20 * 60 * 1000)
          )
          localStorage.setItem('carsTerm', term)
          this.cars = this.$store.state.cars
        }
      }, () => {
        this.snackbar = true
        this.color = 'error'
        this.text = 'No cars match your search'
        this.loaded = false
      })
    }
  },
  created () {
    window.scrollTo(0, 0)
    let data
    if (this.$route.params.term) data = this.$route.params.term
    else data = localStorage.getItem('carsTerm')
    this.getCars(data)
  }
}
</script>

<style scoped>
    .car-search-bg {
        height: auto;
        min-height: fit-content;
        background: linear-gradient(#ff8f95d3, #ff8f95d3), url('../../assets/images/sea.jpg');
        background-color: #333;
        background-size: 100% 100%;
        background-position: center center;
    }
    .car-search-bg .container {
      padding-top: 25px;
    }
</style>
