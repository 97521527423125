<template>
    <div>
        <div v-if="!lastStep" class="d-flex px-5">
            <img v-if="car.carInfo.images.length > 0" :src="car.carInfo.images[0].Url" width="50%">
            <img class="px-3" v-else-if="car.vendorLogo" :src="car.vendorLogo" width="50%">
            <img class="px-3" v-else src="../../assets/images/car.jpg" width="50%">
            <p class="text-h5 secondary--text font-weight-bold mt-5">{{car.vendorName}}</p>
        </div>
        <div v-if="!lastStep"  class="mt-5 px-15">
            <p class="mb-1 body-2" v-if="car.carInfo.carTrans">
                <v-icon left>mdi-car-door</v-icon>
                {{car.carInfo.carType}}
            </p>
            <p class="mb-1 body-2" v-if="car.carInfo.carTrans">
                <v-icon left>mdi-car-cog</v-icon>
                {{car.carInfo.carTrans}}
            </p>
            <p class="mb-1 body-2" v-if="car.carInfo.seats && car.carInfo.seats > 0">
                <v-icon left>mdi-car-seat</v-icon>
                {{car.carInfo.seats}} Seats
            </p>
            <p class="mb-1 body-2" v-if="car.carInfo.carFuel">
                <v-icon left>mdi-fuel</v-icon>
                {{car.carInfo.carFuel}}
            </p>
            <p class="mb-1 body-2" v-if="car.carInfo.bags && car.carInfo.bags.length > 0">
                <v-icon left>mdi-bag-checked</v-icon>
                <span v-for="(item, n) in car.carInfo.bags" :key="n"> . {{ item.Quantity}} {{item.Size}} bag/s </span>
            </p>
        </div>
        <div :class="lastStep ? 'd-block d-md-flex' : ''">
            <div>
                <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Pick-Up Location</p>
                <div>
                    <p class="body-1 paragraph--text ml-5 my-5">{{car.pickUpLocation}}</p>
                    <p class="body-1 paragraph--text ml-5 my-5">{{carInfo.pickup}}</p>
                    <p class="body-1 paragraph--text ml-5 my-5">{{carInfo.pickupTime}}</p>
                </div>
            </div>
            <div>
                <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Drop-off Location</p>
                <div>
                    <p class="body-1 paragraph--text ml-5 my-5">{{car.returnLocation}}</p>
                    <p class="body-1 paragraph--text ml-5 my-5">{{carInfo.drop}}</p>
                    <p class="body-1 paragraph--text ml-5 my-5">{{carInfo.dropTime}}</p>
                </div>
            </div>
        </div>
        <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Total Amount</p>
        <div class="d-flex justify-space-between pr-5">
            <p class="body-1 paragraph--text ml-5 my-5">Total amount to pay</p>
            <p class="body-1 paragraph--text ml-5 my-5">${{car.fareInfo[0].Amount}}</p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['car', 'lastStep'],
  computed: {
    ...mapState(['carInfo'])
  },
  data () {
    return {}
  }
}
</script>
