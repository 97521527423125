<template>
  <div>
    <v-card
    v-for="(car, i) in cars.cars"
    :key="i"
    class="mb-10 py-10"
    :outlined="$route.name === 'carsResults'"
    elevation="0"
    >
      <v-row justify="space-around" align="center">
        <v-col cols="4">
          <img v-if="car.carInfo.images.length > 0" :src="car.carInfo.images[0].Url" width="100%">
          <img class="px-3" v-else-if="car.vendorLogo" :src="car.vendorLogo" width="100%">
          <img class="px-3" v-else src="../../assets/images/car.jpg" width="100%">
        </v-col>
        <v-col cols="12" md="5" class="px-10 py-0">
          <div class="mb-5">
            <span class="headline paragraph--text font-weight-bold">{{car.vendorName}}</span>
            <v-chip
            class="mb-2 ml-5"
            label
            outlined
            color="primary"
            >
              {{car.carInfo.carCategory}}
            </v-chip>
          </div>
          <div>
            <p class="mb-1 body-2" v-if="car.carInfo.carTrans">
              <v-icon left>mdi-car-door</v-icon>
              {{car.carInfo.carType}}
            </p>
            <p class="mb-1 body-2" v-if="car.carInfo.carTrans">
              <v-icon left>mdi-car-cog</v-icon>
              {{car.carInfo.carTrans}}
              </p>
            <p class="mb-1 body-2" v-if="car.carInfo.seats && car.carInfo.seats > 0">
              <v-icon left>mdi-car-seat</v-icon>
              {{car.carInfo.seats}} Seats
            </p>
            <p class="mb-1 body-2" v-if="car.carInfo.carFuel">
              <v-icon left>mdi-fuel</v-icon>
              {{car.carInfo.carFuel}}
            </p>
            <p class="mb-1 body-2" v-if="car.carInfo.bags && car.carInfo.bags.length > 0">
              <v-icon left>mdi-bag-checked</v-icon>
              <span v-for="(item, n) in car.carInfo.bags" :key="n"> . {{ item.Quantity}} {{item.Size}} bag/s </span>
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="3" class="d-flex">
          <v-divider vertical class="d-none d-md-block"></v-divider>
          <div class="text-center mx-auto">
            <div v-for="(fare, l) in car.fareInfo" :key="l">
              <div v-if="fare.ChargeType === 'totalPrice'">
                <p class="text-h4 primary--text font-weight-bold">${{fare.Amount}}</p>
                <span class="caption grey--text">{{fare.ChargeType}}, {{fare.MileageAllowance === 'UNL' ? 'Unlimited' : fare.MileageAllowance}} miles, {{fare.MileageAllowance !== 'UNL' ? `Extra miles $${fare.ExtraMileageCharge}/mile` : ''}}</span>
              </div>
            </div>
            <v-btn v-if="$route.name === 'carsResults'" color="primary white--text" class="rounded-lg mt-5" large elevation="0" @click="select(i, cars.search_id)">
              book car
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn tile color="blueDark white--text" @click="select(i, cars.search_id)">
            Select this car
            <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['cars'],
  data () {
    return {
      now: null
    }
  },
  watch: {
    'this.$store.state.cars' (newVal) {
      this.cars = newVal
    },
    flights () {
      this.now = Date.now()
    }
  },
  methods: {
    select (index, session) {
      // this.$router.push({ name: 'carBook', params: { searchId: session, carId: index } })
      const spentTime = Date.now() - this.now
      const timer = (20 * 60 * 1000) - spentTime
      localStorage.setItem('carsTimer', timer)
      this.$router.push({ name: 'carsBook', params: { searchId: session, carId: index } })
    }
  },
  created () {
    this.now = Date.now()
  }
}
</script>
