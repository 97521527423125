<template>
    <div style="background-color: white;">
      <overlay v-if="carsOverlay"/>
      <v-progress-linear
      indeterminate
      color="primary"
      height="10"
      top
      :active="loaded"
      ></v-progress-linear>
      <div v-if="loaded === false && dataReady === true">
        <v-snackbar id="snackbar" top v-model="snackbar" :color="color" :timeout="5000">
          {{text}}
          <v-btn color="white" icon @click="snackbar = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-snackbar>
        <v-container>
          <v-row justify="space-around" class="my-5">
            <v-btn elevation="0" class="rounded-lg" :text="step !== 1" color="primary" x-large @click="step = 1">Car Selection</v-btn>
            <v-btn elevation="0" class="rounded-lg" :text="step !== 2" color="primary" x-large @click="step = 2">information</v-btn>
            <v-btn elevation="0" class="rounded-lg" :text="step !== 3" color="primary" x-large @click="nextClicked(3)">payment</v-btn>
            <v-btn elevation="0" class="rounded-lg" :text="step !== 4" color="primary" x-large @click="step = 4">confirmation</v-btn>
          </v-row>
        </v-container>
        <div v-show="step === 1">
          <div class="background">
            <v-container>
              <car-card class="mt-9" :cars="{ cars: [car] }"/>
            </v-container>
          </div>
          <v-row justify="center" class="my-5">
            <v-btn x-large class="mx-5 my-1" color="white primary--text" @click="$router.go(-1)">Back to results</v-btn>
            <v-btn x-large class="mx-5 my-1 px-10" color="primary white--text" @click="step = 2">Next</v-btn>
          </v-row>
        </div>
        <div v-show="step === 2">
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-form v-model="validInfo" ref="formInfo">
                  <v-card class="pa-5" outlined>
                    <h2 class="paragraph--text font-weight-bold">Contact Info</h2>
                    <v-row class="mt-5">
                        <v-col cols="6" sm="6" class="py-0" v-if="!$cookies.isKey('userToken') || $store.state.user.role === 'admin' || $store.state.user.role === 'subAgent'">
                          <phoneInput @update="assignPhone" />
                        </v-col>
                        <v-col cols="6" sm="6" class="py-0" v-if="!$cookies.isKey('userToken') || $store.state.user.role === 'admin' || $store.state.user.role === 'subAgent'">
                          <v-text-field
                          outlined
                          v-model="email"
                          :rules="[v => !!v || 'E-mail is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                          label="E-mail"
                          required
                          prepend-inner-icon="mdi-email"
                          color="blue"
                          id="renter-name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="py-0">
                          <v-combobox
                          v-model="title"
                          :items="['Mr', 'Mrs', 'Ms', 'Miss']"
                          :search-input.sync="search"
                          prepend-inner-icon="mdi-card-account-details"
                          hide-selected
                          hint="Add title and press enter to append it"
                          label="Title"
                          outlined
                          :rules="[v => !!v || 'Title is required']"
                          class="pa-0"
                          height="56px"
                          color="blue"
                          id="renter-title"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                <v-list-item-title>
                                  No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="4" class="py-0">
                          <v-text-field
                          outlined
                          v-model="fName"
                          :rules="[v => (!!v && v.length > 1) || 'Item is required at least 2 characters', v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces']"
                          label="First name"
                          required
                          color="blue"
                          id="renter-fName"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="py-0">
                          <v-text-field
                          outlined
                          v-model="lName"
                          :rules="[v => (!!v && v.length > 1) || 'Item is required at least 2 characters', v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces']"
                          label="Last name"
                          required
                          color="blue"
                          id="renter-lName"
                          ></v-text-field>
                        </v-col>
                    </v-row>
                  </v-card>
                  <v-row justify="center" no-gutters class="my-5">
                    <v-btn x-large class="mx-5 px-15 my-1" color="white primary--text" @click="step = 1">Previous</v-btn>
                    <v-btn x-large elevation="0" class="mx-5 px-15 my-1" color="primary white--text" @click="nextClicked(2)">Next</v-btn>
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="12" md="4">
                <v-card class="py-5">
                  <v-card-title class="primary--text text-center text-bold text-h5">
                    <p class="mx-auto my-0">Your Selected Car</p>
                  </v-card-title>
                  <pricingVue :lastStep="false" :car="car" />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-show="step === 3">
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-form v-model="validBilling" ref="formBilling">
                  <address-card @setAddress="setAddress" v-if="paymentType === 'credit'" />

                  <v-card outlined class="pa-5 my-5">
                    <v-card-title><h3 class="paragraph--text font-weight-bold">Payment</h3></v-card-title>
                    <credit v-if="paymentType === 'credit'" @holder="setCardHolder" @card="setCardData" @expire="setCardExpiry" @cvv="setCVV" />
                  </v-card>

                  <v-row justify="center" class="my-5">
                    <v-btn x-large class="mx-5 px-15 my-1" color="white primary--text" @click="step = 2">check details</v-btn>
                    <v-btn x-large :loading="loading" class="mx-5 px-15 my-1" color="primary white--text" @click="nextClicked(3)">complete booking</v-btn>
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="12" md="4">
                <v-card class="pa-5">
                  <v-card-title class="primary--text text-center text-bold text-h5">
                    <p class="mx-auto my-0">Your Selected Car</p>
                  </v-card-title>
                  <pricingVue :lastStep="false" :car="car" />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-show="step === 4">
          <v-container>
            <v-card elevation="3" :width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'" class="mx-auto pa-5">
              <div class="text-center">
                <v-icon size="72" color="success">mdi-check-circle</v-icon>
              </div>
              <h3 class="my-3 paragraph--text text-center">Payment Complete</h3>
            </v-card>
            <h2 class="my-10 paragraph--text text-center font-weight-light">Thank you, Your payment has been successful. A Confirmation has been sent to <span class="font-weight-bold">{{email}}</span></h2>
            <v-card outlined class="pa-5">
              <pricingVue :lastStep="true" :car="car" />
            </v-card>
            <v-btn
              color="primary white--text px-15"
              large
              @click="$router.push({ name: 'Home' })"
              class="my-5 d-block mx-auto"
              elevation="0"
            >
              OK
            </v-btn>
          </v-container>
        </div>
      </div>
      <p v-if="loaded === false && dataReady === false" class="body-1 font-weight-bold text-center primary--text mt-5">This car isn't available rightnow <router-link color="blue" to="/cars">search again</router-link></p>
    </div>
</template>

<script>
import { headersNoAuth, showCar, rentCar } from '@/links'
import overlay from '@/components/flights/overlay'
import credit from '@/components/credit'
import phoneInput from '@/components/mobile.vue'
import carCard from '@/components/cars/card'
import addressCard from '@/components/address.vue'
import pricingVue from '@/components/cars/pricing.vue'

export default {
  title: '-Car Book',
  components: {
    overlay,
    credit,
    carCard,
    phoneInput,
    addressCard,
    pricingVue
  },
  data () {
    return {
      car: {},
      totalPrice: 0,
      phone: '',
      email: '',
      title: '',
      search: '',
      fName: '',
      lName: '',
      zip: '',
      paymentType: 'credit',
      address: '',
      creditNum: '',
      creditEx: '',
      creditType: '',
      cardHolder: '',
      loaded: true,
      dataReady: true,
      responseData: null,
      validInfo: true,
      validBilling: true,
      loading: false,
      dialog: false,
      color: '',
      text: '',
      snackbar: false,
      carsTimeout: null,
      carsOverlay: false,
      cvv: '',
      step: 1
    }
  },
  methods: {
    assignPhone (phone) {
      this.phone = phone
    },
    setAddress (address, zip, streetLine) {
      this.address = address
      this.zip = zip
      // this.streetLine = streetLine
    },
    setCardData (number, type) {
      this.creditNum = number
      switch (type) {
        case 'visa':
          this.creditType = 'VI'
          break
        case 'mastercard':
          this.creditType = 'CA'
          break
        case 'american-express':
          this.creditType = 'AX'
          break
        case 'discover':
          this.creditType = 'DS'
          break
        case 'diners-club':
          this.creditType = 'DN'
          break
        default:
          this.creditType = type
          break
      }
    },
    setCardExpiry (date) {
      this.creditEx = date
    },
    setCVV (cvv) {
      this.cvv = cvv
    },
    setCardHolder (name) {
      this.cardHolder = name
    },
    formatDate (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const newDate = `${months[month - 1]} ${year}`
      return newDate
    },
    nextClicked (step) {
      if (step === 2) {
        this.$refs.formInfo.validate()
        if (this.validInfo) {
          if (this.phone.valid) this.step = 3
          else {
            this.snackbar = true
            this.color = 'error'
            this.text = 'Please provide a vaild mobile number'
            this.loading = false
          }
        } else {
          this.snackbar = true
          this.color = 'warning'
          this.text = 'Please fill all fields'
          this.loading = false
        }
      } else if (step === 3) {
        this.$refs.formBilling.validate()
        if (this.validBilling) {
          if (this.creditEx) {
            if (!this.address && this.$store.state.writtenAddress) this.address = this.$store.state.writtenAddress
            if (!this.zip && this.$store.state.zipCode) this.zip = this.$store.state.zipCode
            if (this.address) {
              if (this.zip) {
                this.rent()
              } else {
                this.snackbar = true
                this.color = 'error'
                this.text = 'Please Enter your ZIP code'
              }
            } else {
              this.snackbar = true
              this.color = 'error'
              this.text = 'Please Enter your address'
              document.getElementsByClassName('address-autocomplete')[0].style.borderColor = 'red'
            }
          } else {
            this.snackbar = true
            this.color = 'error'
            this.text = 'Please Enter credit card expire date'
            document.getElementsByClassName('mx-auto')[0].style.borderColor = 'red'
          }
        } else {
          this.snackbar = true
          this.color = 'warning'
          this.text = 'Please fill all fields'
          this.loading = false
        }
      }
    },
    rent () {
      this.loading = true
      const body = {
        contact_phone: this.phone.nationalNumber,
        countryIsoCode: this.phone.countryCode,
        contact_email: this.email,
        driverTitle: this.title,
        driverFirstName: this.fName,
        driverLastName: this.lName,
        zipCode: this.zip,
        address: this.address,
        creditCardExpireDate: this.creditEx,
        creditCardNumber: this.creditNum,
        creditCardType: this.creditType,
        cardHolderName: this.cardHolder,
        cvv: this.cvv,
        pickupDate: this.$store.state.carInfo.pickup,
        pickupTime: this.$store.state.carInfo.pickupTime,
        returnDate: this.$store.state.carInfo.drop,
        returnTime: this.$store.state.carInfo.dropTime,
        search_id: this.$route.params.searchId,
        car_id: this.$route.params.carId
      }
      this.$http.post(rentCar, body, { headers: headersNoAuth() }).then(response => {
        if (response.data.status) {
          this.loading = false
          this.$store.dispatch('setCarsTimeOut', false)
          if (this.$store.state.carsTimeOutFunction) this.$store.dispatch('clearCarsTimeOutFunction')
          if (this.$store.state.carsSearchTimeOutFunction) this.$store.dispatch('clearCarsSearchTimeOutFunction')
          this.step = 4
        } else {
          this.loading = false
          this.snackbar = true
          this.color = 'error'
          this.text = response.data.message
        }
      }, () => {
        this.loading = false
        this.snackbar = true
        this.color = 'error'
        this.text = 'Something went wrong please try again in few minutes'
      })
    }
  },
  created () {
    window.scrollTo(0, 0)
    const term = `car_id=${this.$route.params.carId}&search_id=${this.$route.params.searchId}`
    this.$http.get(showCar(term), { headers: headersNoAuth() }).then(response => {
      if (response.data.status === true) {
        this.loaded = false
        this.dataReady = true
        this.car = response.data.data
        this.car.fareInfo.forEach(fare => {
          if (fare.ChargeType === 'totalPrice') this.totalPrice = fare.Amount
        })
      } else {
        this.loaded = false
        this.dataReady = false
      }
    })
    this.carsTimeout = setTimeout(() => {
      this.carsOverlay = true
    }, localStorage.getItem('carsTimer'))
  },
  beforeDestroy () {
    clearTimeout(this.carsTimeout)
    this.carsOverlay = false
  }
}
</script>

<style>
    .background {
      height: auto;
      min-height: fit-content;
      background: linear-gradient(#ff8f95d3, #ff8f95d3), url('../../assets/images/sea.jpg');
      background-color: #333;
      background-size: 100% 100%;
      background-position: center center;
    }
    .remove-controls input[type='number'] {
    -moz-appearance:textfield;
    }
    .remove-controls input::-webkit-outer-spin-button,
    .remove-controls input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .form {
        border-left: 0.1px solid #d2d2d2
    }
    @media screen and (max-width: 599px) {
        .form {
            border-top: 0.1px solid #d2d2d2
        }
    }
</style>
